import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Courts from "./components/CourtsList";
import CourtsCreate from "./components/CreateCourt";
import Login from "./components/auth/Login";
import LoginOTP from "./components/auth/LoginOTP";
import ForgotPass from "./components/auth/ForgotPassword";
import CourtDetail from "./components/CourtDetail"; // Adjust the path as needed
import CourtEdit from "./components/CourtEdit"; // Adjust the path as needed
import Contact from "./components/Contact"; // Adjust the path as needed
import OwnCourts from "./components/OwnCourts"; // Adjust the path as needed
import AdminUsers from "./components/AdminUsers"; // Adjust the path as needed
import AdminCourts from "./components/AdminCourts"; // Adjust the path as needed
import Leaderboards from "./components/Leaderboard"; // Adjust the path as needed

import EditUser from "./components/EditUser"; // Adjust the path as needed
import Register from "./components/auth/Register";
import Navbar from "./components/Navbar";
import { Provider } from "react-redux";
import store from "./store";
import { useDispatch } from "react-redux";
import { rehydrateUser } from "./actions/authActions";
import React, { useEffect } from "react";
import Leaderboard from "./components/Leaderboard";
const token = localStorage.getItem("userToken");

function parseJwt(token) {
  try {
    const base64 = token.split(".")[1].replace(/-/g, "+").replace(/_/g, "/");
    const rawData = atob(base64);
    const len = rawData.length;
    const array = new Uint8Array(new ArrayBuffer(len));

    for (let i = 0; i < len; i++) {
      array[i] = rawData.charCodeAt(i);
    }

    const text = new TextDecoder().decode(array);
    return JSON.parse(text);
  } catch (e) {
    console.error("Error:", e); // Log the error for debugging
    return null;
  }
}
function withRoleProtection(WrappedComponent, roles) {
  return function RoleProtectedWrapper(props) {
    const token2 = localStorage.getItem("userToken");

    const userData = token2 ? parseJwt(token2) : null;
    if (userData && roles.includes(userData.role)) {
      return <WrappedComponent {...props} />;
    } else {
      alert("Not Authorized!");

      return <Navigate to="/" replace />;
    }
  };
}
const ProtectedAdminUsers = withRoleProtection(AdminUsers, ["admin"]);
const ProtectedAdminCourts = withRoleProtection(AdminCourts, ["admin"]);
const ProtectedEditUser = withRoleProtection(EditUser, [
  "admin",
  "pro",
  "user",
]);
const ProtectedOwnCourts = withRoleProtection(OwnCourts, [
  "admin",
  "pro",
  "user",
]);
const ProtectedCourtsCreate = withRoleProtection(CourtsCreate, [
  "admin",
  "pro",
]);
const ProtectedCourtDetail = withRoleProtection(CourtDetail, [
  "admin",
  "pro",
  "user",
]);
const ProtectedCourtEdit = withRoleProtection(CourtEdit, ["admin", "pro"]);

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    if (token) {
      const userData = parseJwt(token);
      if (userData) {
        dispatch(rehydrateUser(userData));
      }
    }
  }, [dispatch]);
  return (
    <Provider store={store}>
      <Router>
        <Navbar />
        <div className="App">
          <Routes>
            <Route path="/" element={<Courts />} exact />
            <Route path="/courts" element={<Courts />} />
            <Route path="/login" element={<Login />} />
            <Route path="/loginotp" element={<LoginOTP />} />
            <Route path="/register" element={<Register />} />
            <Route path="/courts/create" element={<ProtectedCourtsCreate />} />
            <Route path="/resetpassword" element={<ForgotPass />} />
            <Route path="/courts/detail/:courtId" element={<CourtDetail />} />
            <Route
              path="/courts/edit/:courtId"
              element={<ProtectedCourtEdit />}
            />
            <Route path="/contact" element={<Contact />} />
            <Route path="/own-courts" element={<ProtectedOwnCourts />} />
            <Route path="/adminusers" element={<ProtectedAdminUsers />} />

            <Route
              path="/profile/update/:userId"
              element={<ProtectedEditUser />}
            />
            <Route path="/admincourts" element={<ProtectedAdminCourts />} />
            <Route path="/leaderboard" element={<Leaderboards />} />

            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
