import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";

function Register() {
  const apiBaseURL = process.env.REACT_APP_BACKEND_URL;
  const [isLoading, setIsLoading] = useState(false);

  const formatDate = (dateString) => {
    return moment(dateString).format("YYYY-MM-DD");
  };
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);
  // Additional states for SMS verification
  const [verificationId, setVerificationId] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [countdown, setCountdown] = useState(30); // initial value of 60 seconds
  const [avatar, setAvatar] = useState(null); // State to hold the avatar file
  const [avatarPreview, setAvatarPreview] = useState(null);

  const [formData, setFormData] = useState({
    username: "",
    phone: "",
    countryCode: "60",
    skill_level_default: "beginner",
    gender: "male",
    dob: "",
    password: "",
    avatar: "",
  });

  useEffect(() => {
    let timer;

    if (isCodeSent && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (!countdown) {
      setIsCodeSent(false);
      setCountdown(60); // reset countdown
    }

    return () => clearTimeout(timer); // Clear the timer to prevent memory leaks
  }, [isCodeSent, countdown]);
  const [responseMessage, setResponseMessage] = useState("");
  const handleSendCode = async () => {
    try {
      setIsLoading(true);
      if (!formData.phone.startsWith(formData.countryCode)) {
        var fullPhoneNumber = formData.countryCode + formData.phone;
        //formData.phone = fullPhoneNumber;
      }
      const response = await axios.post(
        `${apiBaseURL}/users/register/sendcode`,
        {
          phone: fullPhoneNumber,
        }
      );
      setVerificationId(response.data.id);
      setResponseMessage("Verification code sent to your phone.");
      setIsCodeSent(true);
    } catch (error) {
      if (error?.response.status === 409) {
        setResponseMessage("Phone number is registered");
      } else {
        setResponseMessage(
          "Error sending verification code. Please try again."
        );
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleChange = (e) => {
    let value = e.target.value;
    if (["dob"].includes(e.target.name)) {
      value = formatDate(value);
    }
    if (["avatar"].includes(e.target.name)) {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();

        reader.onloadend = () => {
          setAvatarPreview(reader.result);
        };

        reader.readAsDataURL(file);
        formData.avatar = file;
        setAvatar(file);
      }
    }

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    var originalPhoneNumber = formData.phone;
    var originalcountryCode = formData.countryCode;
    setIsLoading(true);

    try {
      // Create a FormData object

      if (!verificationCode) {
        setResponseMessage("Please fill in the OTP.");
        return;
      }
      if (!formData.phone.startsWith(formData.countryCode)) {
        var fullPhoneNumber = formData.countryCode + formData.phone;
        formData.phone = fullPhoneNumber;
      }
      const data = new FormData();
      Object.keys(formData).forEach((key) => {
        if (key !== "avatar" && formData[key]) {
          data.append(key, formData[key]);
        }
      });
      if (avatar) {
        data.append("avatar", avatar);
      }
      if (verificationCode) {
        data.append("code", verificationCode);
      }
      if (verificationId) {
        data.append("id", verificationId);
      }
      const response = await axios.post(
        `${apiBaseURL}/users/register/verify`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        navigate("/login");
      }
    } catch (error) {
      // Handle errors
      if (error.response && error.response.status === 401) {
        setResponseMessage("Invalid verification code. Please try again.");
      } else if (error.response && error.response.status === 409) {
        setResponseMessage("Account already exists.");
      } else if (error.response && error.response.status === 408) {
        setResponseMessage("Invalid verification code. Please try again.");
      } else {
        setResponseMessage("Registration failed. Please try again.");
      }
    } finally {
      setFormData({ ...formData, phone: originalPhoneNumber });

      setIsLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <Card>
            <Card.Header as="h3" className="text-center bg-primary text-white">
              Account Registration
            </Card.Header>
            <Card.Body>
              <form onSubmit={handleSubmit}>
                {/* {avatarPreview && (
                  <div className="mb-3">
                    <div className="text-center">
                      <label className="form-label">Avatar Preview</label>
                    </div>
                    <div className="d-flex justify-content-center">
                      <img
                        src={avatarPreview}
                        alt="avatar"
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="mb-3">
                  <label className="form-label">
                    Avatar Image Upload (Optional)
                  </label>
                  <input
                    name="avatar"
                    type="file"
                    className="form-control"
                    accept="image/*" // Only accept image files
                    onChange={handleChange}
                    value={formData.avatar}
                  />
                </div> */}
                <div className="mb-3">
                  <label className="form-label">
                    NickName* (Cannot be change after create){" "}
                  </label>
                  <input
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Name"
                    required
                  />
                </div>
                <div className="mb-3 row">
                  <label className="form-label col-sm-2 col-form-label">
                    Phone Number*
                  </label>
                  <div className="col-sm-2">
                    <select
                      name="countryCode"
                      onChange={handleChange}
                      value={formData.countryCode}
                      className="form-control"
                      required
                    >
                      <option value="60">+60 (Malaysia)</option>
                      <option value="65">+65 (Singapore)</option>
                    </select>
                  </div>
                  <div className="col-sm-8">
                    <input
                      name="phone"
                      type="phone"
                      className="form-control"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Enter Phone Number"
                      required
                    />
                  </div>
                  <div className="col-sm-12 d-flex justify-content-end">
                    <button
                      style={{ marginTop: "10px" }}
                      type="button"
                      className={`btn ${
                        isCodeSent ? "btn-secondary" : "btn-success"
                      }`}
                      onClick={handleSendCode}
                      disabled={isCodeSent}
                    >
                      {isCodeSent
                        ? `Resend in ${countdown} seconds`
                        : "Send Code"}
                    </button>
                  </div>
                </div>
                {isCodeSent && (
                  <div className="mb-3">
                    <label className="form-label">Verification Code*</label>
                    <input
                      type="number"
                      className="form-control"
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                      placeholder="Enter the code sent to your phone"
                    />
                  </div>
                )}
                <div className="mb-3">
                  <label className="form-label">Gender*</label>
                  <select
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    className="form-control"
                    required
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label className="form-label">Skill Level*</label>
                  <select
                    name="skill_level_default"
                    value={formData.skill_level_default}
                    onChange={handleChange}
                    className="form-control"
                    required
                  >
                    <option value="newbie">Newbie</option>
                    <option value="beginner">Beginner</option>
                    <option value="intermediate">Intermediate</option>
                    <option value="advanced">Advanced</option>
                    <option value="master">Master</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label className="form-label">Date of Birth*</label>
                  <input
                    name="dob"
                    type="date"
                    value={formData.dob}
                    onChange={handleChange}
                    className="form-control"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Password*</label>
                  <input
                    name="password"
                    type="password"
                    value={formData.password}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Password"
                    required
                  />
                </div>

                <button
                  disabled={isLoading}
                  className="btn btn-success w-100"
                  type="submit"
                >
                  {isLoading ? "Submiting..." : "Submit"}
                </button>
              </form>
            </Card.Body>
          </Card>
          {responseMessage && (
            <div className="mt-4 alert alert-info" role="alert">
              {responseMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Register;
