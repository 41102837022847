import React from "react";
import { Card, Button } from "react-bootstrap";

function Contact() {
  return (
    <div style={styles.container}>
      <Card style={styles.card}>
        <Card.Body>
          <Card.Title>Contact Information</Card.Title>
          <Card.Text>
            Contact Me at Telegram or Email to convert your role to host a court
            or collaboration.
          </Card.Text>
          <Button
            variant="primary"
            style={{ marginRight: "20px" }}
            href="https://t.me/ezbook_court"
            target="_blank"
          >
            Message on Telegram
          </Button>
          <Button
            variant="primary"
            href=" mailto:ez.bookcourt@gmail.com"
            target="_blank"
          >
            Email Me
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    padding: "20px",
    backgroundColor: "#f9f9f9",
  },
  card: {
    width: "100%",
    maxWidth: "500px",
  },
};

export default Contact;
