import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";

function CourtEdit() {
  const apiBaseURL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem("userToken"); // or wherever you store it
  const normalFormatDate = (dateString) => {
    return moment.utc(dateString).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  };
  function formatDate(dateTimeString) {
    if (dateTimeString) {
      // Split date and time
      const [datePart, timePart] = dateTimeString.split("T");
      const [year, month, day] = datePart.split("-");
      let [hours, minutes] = timePart.split(":");

      // Convert to 12-hour format with AM/PM
      //hours = (((parseInt(hours, 10) - 1) % 12) + 1).toString(); // Convert hour '0' to '12' and 24-hour to 12-hour format

      return `${year}-${month}-${day} ${hours}:${minutes}`;
    }
  }
  const formatCourtsNum = (courts) => {
    return courts.map((num) => (num === "" ? "" : num)).join(" @");
  };
  const { courtId } = useParams();
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState(null);

  const [errorMessage, setErrorMessage] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    start_time: "",
    end_time: "",
    location: "",
    courts_num: "",
    male_price: "",
    female_price: "",
    pricing_mode: "",
    maximum_players: "",
    description: "",
    skill_level: "",
    status: "",
    requirement: "",
    room_password: "",
    num_males: "",
    num_females: "",
    total_cost: "0",
  });

  useEffect(() => {
    // Fetch court details when the component mounts and populate formData
    const fetchCourtDetails = async () => {
      try {
        const response = await axios.post(
          `${apiBaseURL}/courts/${courtId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`, // Make sure to pass your JWT token here
            },
          }
        );

        // Assuming you have the list_of_players in your component's state or as props:
        const { num_males, num_females } = calculateGenderCounts(
          response.data.list_of_players
        );

        const updatedData = {
          ...response.data,
          courts_num: formatCourtsNum(response.data.courts_num),
          start_time: formatDate(response.data.start_time),
          end_time: formatDate(response.data.end_time),
          num_males: num_males,
          num_females: num_females,
        };
        setFormData(updatedData);
      } catch (error) {
        console.error("Error fetching court details:", error);
      }
    };

    fetchCourtDetails();
  }, [courtId]);

  const handleChange = (event) => {
    const { name, value: originalValue } = event.target;

    // Handle date formatting if the name is start_time or end_time
    const value = ["start_time", "end_time"].includes(name)
      ? formatDate(originalValue)
      : originalValue;

    let updatedFormData = { ...formData, [name]: value };

    // Adjust the ratio if one of the genders is 0
    if (updatedFormData.num_males === 0 && updatedFormData.num_females !== 0) {
      updatedFormData.male_to_female_ratio = 0; // All cost goes to females
    } else if (
      updatedFormData.num_females === 0 &&
      updatedFormData.num_males !== 0
    ) {
      updatedFormData.male_to_female_ratio = 1; // All cost goes to males
    }

    // Recalculate the shared costs
    if (
      [
        "male_to_female_ratio",
        "num_males",
        "num_females",
        "total_cost",
      ].includes(name)
    ) {
      updatedFormData.male_price = (
        (updatedFormData.total_cost * updatedFormData.male_to_female_ratio) /
        (updatedFormData.num_males || 1)
      ) // Prevent division by zero
        .toFixed(2);

      updatedFormData.female_price = (
        (updatedFormData.total_cost *
          (1 - updatedFormData.male_to_female_ratio)) /
        (updatedFormData.num_females || 1)
      ) // Prevent division by zero
        .toFixed(2);
    }
    setFormData(updatedFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const stringArray = formData.courts_num.split("@").map((str) => str.trim());
    const updatedData = {
      ...formData,
      start_time: normalFormatDate(formData.start_time),
      end_time: normalFormatDate(formData.end_time),
      courts_num: stringArray,
    };
    console.log(updatedData);
    try {
      await axios.put(
        `${apiBaseURL}/courts/edit/${courtId}`,
        { ...updatedData },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Make sure to pass your JWT token here
          },
        }
      );
      navigate("/courts"); // Assuming you want to navigate back to a list of courts
    } catch (error) {
      console.error("Error editing court:", error);
    }
  };
  function CurrentDateTime() {
    const date = new Date();
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // month is zero-indexed
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  // This assumes you have a mechanism to get the list_of_players and each player has a user object with a gender field.
  const calculateGenderCounts = (list_of_players) => {
    let num_males = 0;
    let num_females = 0;

    list_of_players.forEach((player) => {
      if (player.user.gender === "male") num_males++;
      else if (player.user.gender === "female") num_females++;
    });
    return { num_males, num_females };
  };

  return (
    <div className="container mt-5">
      <Card>
        <Card.Header as="h3" className="text-center bg-primary text-white">
          <h2>Court Setting</h2>
        </Card.Header>
        <Card.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">Status</label>
              <select
                name="status"
                value={formData.status}
                onChange={handleChange}
                className="form-control"
                required
              >
                <option value="open">Open</option>
                <option value="playing">Playing</option>
                <option value="closed">Closed</option>
                <option value="payment">Payment</option>
                <option value="completed">Completed</option>
              </select>
            </div>

            <div className="mb-3">
              <label className="form-label">Court Name</label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter court name"
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Location</label>
              <input
                type="text"
                className="form-control"
                name="location"
                value={formData.location}
                onChange={handleChange}
                placeholder="Enter court location"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">
                Court Number (separated by @)
              </label>
              <input
                type="text"
                className="form-control"
                name="courts_num"
                value={formData.courts_num}
                onChange={handleChange}
                placeholder="Example: @3 @6"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Start Time</label>
              <input
                className="form-control"
                type="datetime-local"
                name="start_time"
                min={CurrentDateTime()}
                value={formData.start_time}
                onChange={handleChange}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">End Time</label>
              <input
                className="form-control"
                type="datetime-local"
                name="end_time"
                min={CurrentDateTime()}
                value={formData.end_time}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Privacy</label>
              <select
                className="form-select"
                name="requirement"
                value={formData.requirement}
                onChange={handleChange}
              >
                <option value="public">Public</option>
                <option value="private">Private</option>
              </select>
            </div>
            {formData.requirement === "private" && (
              <div className="mb-3">
                <label className="form-label">Room Password</label>
                <input
                  type="text"
                  className="form-control"
                  name="room_password"
                  value={formData.room_password}
                  onChange={handleChange}
                  placeholder="Enter 6-digit password"
                  maxLength="6"
                  inputMode="numeric"
                  pattern="\d{6}" // This is for form validation on submit
                  title="Password should be 6 digits"
                />
              </div>
            )}

            <div className="mb-3">
              <label className="form-label">Pricing Mode</label>
              <select
                className="form-select"
                name="pricing_mode"
                value={formData.pricing_mode}
                onChange={handleChange}
              >
                <option value="fixed">Fixed</option>
                <option value="shared">Shared</option>
              </select>
            </div>
            {formData.pricing_mode === "shared" && (
              <>
                <div className="mb-3">
                  <label className="form-label">Total Cost</label>
                  <input
                    type="number"
                    min="0"
                    step="0.01"
                    className="form-control"
                    name="total_cost"
                    value={formData.total_cost}
                    onChange={handleChange}
                    placeholder="Enter total cost for the court"
                    required
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label">Number of Males</label>
                  <input
                    type="number"
                    className="form-control"
                    name="num_males"
                    value={formData.num_males}
                    onChange={handleChange}
                    min="0"
                    required
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label">Number of Females</label>
                  <input
                    type="number"
                    className="form-control"
                    name="num_females"
                    value={formData.num_females}
                    onChange={handleChange}
                    min="0"
                    required
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label">
                    Just for Calculation, Not Recorded
                    <br />
                    Male to Female Fee Ratio: {formData.male_to_female_ratio}
                  </label>
                  <input
                    type="range"
                    className="form-control"
                    min="0.1"
                    max="1"
                    step="0.01"
                    name="male_to_female_ratio"
                    value={formData.male_to_female_ratio}
                    onChange={handleChange}
                  />
                </div>

                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Male Fee</label>
                    <input
                      type="text"
                      className="form-control"
                      value={formData.male_price}
                      readOnly
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="form-label">Female Fee</label>
                    <input
                      type="text"
                      className="form-control"
                      value={formData.female_price}
                      readOnly
                    />
                  </div>
                </div>
              </>
            )}
            {formData.pricing_mode === "fixed" && (
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="form-label">Male Fee</label>
                  <input
                    type="text"
                    className="form-control"
                    name="male_price"
                    value={formData.male_price}
                    onChange={handleChange}
                    placeholder="Enter male fee"
                    required
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label className="form-label">Female Fee</label>
                  <input
                    type="text"
                    className="form-control"
                    name="female_price"
                    value={formData.female_price}
                    onChange={handleChange}
                    placeholder="Enter female fee"
                    required
                  />
                </div>
              </div>
            )}

            <div className="mb-3">
              <label className="form-label">Maximum Players</label>
              <input
                type="number"
                min="2"
                className="form-control"
                name="maximum_players"
                value={formData.maximum_players}
                onChange={handleChange}
                placeholder="Enter maximum allowed players"
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Description</label>
              <textarea
                className="form-control"
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Enter a brief description of the court"
                rows="3"
              ></textarea>
            </div>
            <div className="mb-3">
              <label className="form-label">Skill Level</label>
              <select
                className="form-select"
                name="skill_level"
                value={formData.skill_level}
                onChange={handleChange}
              >
                <option value="newbie">Newbie</option>
                <option value="beginner">Beginner</option>
                <option value="intermediate">Intermediate</option>
                <option value="advanced">Advanced</option>
                <option value="master">Master</option>
              </select>
            </div>

            <Button variant="success" className="w-100 mb-3" type="submit">
              Submit
            </Button>

            {successMessage && (
              <div className="alert alert-success mt-3">{successMessage}</div>
            )}
            {errorMessage && (
              <div className="alert alert-danger mt-3">{errorMessage}</div>
            )}
          </form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default CourtEdit;
