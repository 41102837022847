import React, { useState, useEffect } from "react";

import axios from "axios";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

function EditUser({ match }) {
  const token = localStorage.getItem("userToken"); // or wherever you store it
  const currentUserData = token ? parseJwt(token) : null;
  const isAdmin = currentUserData && currentUserData.role === "admin";

  const navigate = useNavigate();
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().slice(0, 10);
  };
  const { userId } = useParams();
  const apiBaseURL = process.env.REACT_APP_BACKEND_URL;
  const [avatar, setAvatar] = useState(null); // State to hold the avatar file
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [user, setUser] = useState({
    username: "",
    gender: "",
    dob: "",
    skill_level_default: "",
    skill_rating: "",
    feiji: "",
    role: "",
    status: "",
    avatar: "",
    credit: "",
  });

  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await axios.get(`${apiBaseURL}/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Make sure to pass your JWT token here
          },
        });
        const fetchedUser = response.data;
        fetchedUser.dob = formatDate(fetchedUser.dob);
        setUser(fetchedUser);
      } catch (error) {
        console.log(error);
        if (error.response.status === 403) {
          alert("You are not authorized to view this user.");
          navigate("/courts");
        }
      }
    }

    fetchUserData();
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "avatar") {
      const file = e.target.files[0];

      // Generate a new preview URL
      const previewUrl = URL.createObjectURL(file);
      setAvatarPreview(previewUrl);

      // Create a preview URL
      const reader = new FileReader();

      reader.readAsDataURL(file);
      user.avatar = file;
      setAvatar(file);
    } else {
      setUser((prevUser) => ({
        ...prevUser,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();

    // Append all other user properties to formData
    Object.keys(user).forEach((key) => {
      if (key !== "avatar" && user[key] != null) {
        const value =
          typeof user[key] === "number" ? user[key].toString() : user[key];
        data.append(key, value);
      }
    });

    // Append the avatar file to formData
    if (avatar) {
      data.append("avatar", avatar);
    }

    try {
      await axios.put(`${apiBaseURL}/users/edit/${userId}`, data, {
        headers: {
          Authorization: `Bearer ${token}`, // Make sure to pass your JWT token here
        },
      });
      alert("User updated successfully");

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.log(error);
      alert("Error updating user");
    }
  };
  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col md={6}>
          <Card>
            <Card.Header as="h3" className="text-center bg-primary text-white">
              Edit Profile
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                {/* {(avatarPreview || user.avatar) && (
                  <div className="mb-3">
                    <div className="text-center">
                      <label className="form-label">Avatar Preview</label>
                    </div>
                    <div className="d-flex justify-content-center">
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                        }}
                        src={
                          avatarPreview
                            ? avatarPreview
                            : `data:image/jpeg;base64,${user.avatar}`
                        }
                      />
                    </div>
                  </div>
                )}

                <div className="mb-3">
                  <label className="form-label">Avatar</label>
                  <input
                    type="file"
                    name="avatar"
                    className="form-control"
                    onChange={handleChange}
                  />
                </div> */}
                <Form.Group className="mb-3">
                  <Form.Label>NickName</Form.Label>
                  <Form.Control
                    type="text"
                    disabled={!isAdmin}
                    name="username"
                    value={user.username}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    disabled="true"
                    type="text"
                    name="phone"
                    value={user.phone}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Gender</Form.Label>
                  <Form.Control
                    as="select"
                    disabled={!isAdmin}
                    name="gender"
                    value={user.gender}
                    onChange={handleChange}
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control
                    type="date"
                    name="dob"
                    value={user.dob}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Skill Level</Form.Label>
                  <Form.Control
                    as="select"
                    name="skill_level_default"
                    value={user.skill_level_default}
                    onChange={handleChange}
                  >
                    <option value="newbie">Newbie</option>
                    <option value="beginner">Beginner</option>
                    <option value="intermediate">Intermediate</option>
                    <option value="advanced">Advanced</option>
                    <option value="master">Master</option>
                  </Form.Control>
                </Form.Group>

                {isAdmin && (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label>Skill Rating</Form.Label>
                      <Form.Control
                        type="number"
                        name="skill_rating"
                        value={user.skill_rating}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Feiji</Form.Label>
                      <Form.Control
                        type="number"
                        name="feiji"
                        value={user.feiji}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Credit</Form.Label>
                      <Form.Control
                        type="number"
                        name="credit"
                        value={user.credit}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Role</Form.Label>
                      <Form.Control
                        as="select"
                        name="role"
                        value={user.role}
                        onChange={handleChange}
                      >
                        <option value="user">User</option>
                        <option value="pro">Pro</option>
                        <option value="admin">Admin</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="status"
                        value={user.status}
                        onChange={handleChange}
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </Form.Control>
                    </Form.Group>
                  </>
                )}

                <div className="d-grid gap-2">
                  <Button variant="primary" type="submit">
                    Update
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
function parseJwt(token) {
  try {
    const base64 = token.split(".")[1].replace(/-/g, "+").replace(/_/g, "/");
    const rawData = atob(base64);
    const len = rawData.length;
    const array = new Uint8Array(new ArrayBuffer(len));

    for (let i = 0; i < len; i++) {
      array[i] = rawData.charCodeAt(i);
    }

    const text = new TextDecoder().decode(array);
    return JSON.parse(text);
  } catch (e) {
    console.error("Error:", e); // Log the error for debugging
    return null;
  }
}
export default EditUser;
