import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Table, Button } from "react-bootstrap";
function AdminCourts() {
  const [courts, setCourts] = useState([]);
  const navigate = useNavigate();
  const apiBaseURL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem("userToken"); // or wherever you store it

  useEffect(() => {
    async function fetchCourts() {
      const response = await axios.get(`${apiBaseURL}/courts`, {
        headers: {
          Authorization: `Bearer ${token}`, // Make sure to pass your JWT token here
        },
      });
      setCourts(response.data);
    }
    fetchCourts();
  }, []);

  const handleEdit = (court) => {
    navigate(`/courts/edit/${court._id}`);
  };
  const handleDelete = (court) => {
    async function deleteCourt() {
      const response = await axios.delete(
        `${apiBaseURL}/courts/delete/${court._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Make sure to pass your JWT token here
          },
        }
      );
      console.log(response.data);
      setCourts(response.data);
    }
    deleteCourt();
  };
  return (
    <div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Court ID</th>
            <th>Court Name</th>
            <th>Status</th>
            <th>Created By</th>
            <th>Phone</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(courts) &&
            courts.map((court) => (
              <tr key={court._id}>
                <td>{court._id}</td>
                <td>{court.name}</td>
                <td>{court.status}</td>
                <td>{court.created_by.username}</td>
                <td>{court.created_by.phone}</td>

                <td>
                  <Button
                    variant="primary"
                    style={{ marginRight: "20px" }}
                    onClick={() => handleEdit(court)}
                  >
                    Edit
                  </Button>
                  <Button variant="primary" onClick={() => handleDelete(court)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
}

export default AdminCourts;
