import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Table, Button } from "react-bootstrap";
function AdminUsers() {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const apiBaseURL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem("userToken"); // or wherever you store it

  useEffect(() => {
    async function fetchUsers() {
      const response = await axios.get(`${apiBaseURL}/users/adminview`, {
        headers: {
          Authorization: `Bearer ${token}`, // Make sure to pass your JWT token here
        },
      });
      setUsers(response.data);
    }
    fetchUsers();
  }, []);

  const handleEdit = (user) => {
    // You can either use a modal here or navigate to an EditUser page.
    // For simplicity, I'll navigate to another page:
    navigate(`/profile/update/${user._id}`);
  };

  return (
    <div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>User ID</th>
            <th>Username</th>
            <th>Role</th>
            <th>Phone</th>
            <th>Gender</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(users) &&
            users.map((user) => (
              <tr key={user._id}>
                <td>{user._id}</td>
                <td>{user.username}</td>
                <td>{user.role}</td>
                <td>{user.phone}</td>
                <td>{user.gender}</td>
                <td>{user.status}</td>
                <td>
                  <Button variant="primary" onClick={() => handleEdit(user)}>
                    Edit
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
}

export default AdminUsers;
