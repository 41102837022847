// components/CreateCourt.js

import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";

function CreateCourt() {
  const apiBaseURL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem("userToken"); // or wherever you store it
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const formatDate = (dateString) => {
    return moment(dateString).format("YYYY-MM-DDTHH:mm");
  };
  const [formData, setFormData] = useState({
    name: "",
    start_time: formatDate(CurrentDateTime()),
    end_time: formatDate(CurrentDateTime()),
    location: "",
    courts_num: 10,
    male_price: "",
    female_price: "",
    pricing_mode: "fixed", // default value
    maximum_players: "",
    description: "",
    skill_level: "beginner",
    status: "open", // default value
    requirement: "public", // default value
    room_password: "",
    total_cost: "0",
  });
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    let value2 = e.target.value;

    if (["start_time", "end_time"].includes(e.target.name)) {
      value2 = formatDate(value2);
    }
    if (name === "room_password") {
      const onlyNumbers = value.replace(/[^\d]/g, "");
      if (onlyNumbers.length <= 6) {
        setFormData((prev) => ({
          ...prev,
          [name]: onlyNumbers,
        }));
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  function CurrentDateTime() {
    const date = new Date();
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // month is zero-indexed
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      // console.log("submit create");
      const response = await axios.post(
        `${apiBaseURL}/courts/create`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccessMessage("Court created successfully!"); // setting success message
      navigate("/courts"); // redirecting after 1.5 seconds
      // You can use something like react-toastify to show a success message
    } catch (error) {
      console.error(error);

      console.error(error.message);
      setErrorMessage(error.response?.data?.message || "Error creating court."); // setting error message
      // Show error message
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <Card>
        <Card.Header as="h3" className="text-center bg-primary text-white">
          <h2>Create Court</h2>
        </Card.Header>
        <Card.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">Court Name</label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter court name"
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Location</label>
              <input
                type="text"
                className="form-control"
                name="location"
                value={formData.location}
                onChange={handleChange}
                placeholder="Enter court location"
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Start Time</label>
              <input
                className="form-control"
                type="datetime-local"
                name="start_time"
                min={CurrentDateTime()}
                value={formData.start_time}
                onChange={handleChange}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">End Time</label>
              <input
                className="form-control"
                type="datetime-local"
                name="end_time"
                min={CurrentDateTime()}
                value={formData.end_time}
                onChange={handleChange}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Privacy</label>
              <select
                className="form-select"
                name="requirement"
                value={formData.requirement}
                onChange={handleChange}
              >
                <option value="public">Public</option>
                <option value="private">Private</option>
              </select>
            </div>

            {formData.requirement === "private" && (
              <div className="mb-3">
                <label className="form-label">Room Password</label>
                <input
                  type="text"
                  className="form-control"
                  name="room_password"
                  value={formData.room_password}
                  onChange={handleChange}
                  placeholder="Enter 6-digit password"
                  maxLength="6"
                  inputMode="numeric"
                  pattern="\d{6}"
                  title="Password should be 6 digits"
                />
              </div>
            )}

            {/* <div className="mb-3">
              <label className="form-label">Number of Courts</label>
              <input
                type="number"
                min="1"
                className="form-control"
                name="courts_num"
                value={formData.courts_num}
                onChange={handleChange}
                placeholder="Enter number of available courts"
                required
              />
            </div> */}

            <div className="mb-3">
              <label className="form-label">Pricing Mode</label>
              <select
                className="form-select"
                name="pricing_mode"
                value={formData.pricing_mode}
                onChange={handleChange}
              >
                <option value="fixed">Fixed</option>
                <option value="shared">Shared</option>
              </select>
            </div>

            {formData.pricing_mode === "fixed" && (
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="form-label">Male Fee</label>
                  <input
                    type="text"
                    className="form-control"
                    name="male_price"
                    value={formData.male_price}
                    onChange={handleChange}
                    placeholder="Enter male fee"
                    required
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label className="form-label">Female Fee</label>
                  <input
                    type="text"
                    className="form-control"
                    name="female_price"
                    value={formData.female_price}
                    onChange={handleChange}
                    placeholder="Enter female fee"
                    required
                  />
                </div>
              </div>
            )}

            <div className="mb-3">
              <label className="form-label">Maximum Players</label>
              <input
                type="number"
                min="2"
                className="form-control"
                name="maximum_players"
                value={formData.maximum_players}
                onChange={handleChange}
                placeholder="Enter maximum allowed players"
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">Description</label>
              <textarea
                className="form-control"
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Enter a brief description of the court"
                rows="3"
              ></textarea>
            </div>

            <div className="mb-3">
              <label className="form-label">Skill Level</label>
              <select
                className="form-select"
                name="skill_level"
                value={formData.skill_level}
                onChange={handleChange}
              >
                <option value="newbie">Newbie</option>
                <option value="beginner">Beginner</option>
                <option value="intermediate">Intermediate</option>
                <option value="advanced">Advanced</option>
                <option value="master">Master</option>
              </select>
            </div>

            <Button
              variant="success"
              disabled={isLoading}
              className="w-100 mb-3"
              type="submit"
            >
              {isLoading ? "Creating..." : "Create"}
            </Button>
          </form>

          {successMessage && (
            <div className="alert alert-success mt-3">{successMessage}</div>
          )}
          {errorMessage && (
            <div className="alert alert-danger mt-3">{errorMessage}</div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default CreateCourt;
