const initialState = {
  isAuthenticated: false,
  user: null,
  errorMessage: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_USER":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        errorMessage: "",
      };
    case "LOGIN_ERROR":
      return {
        ...state,
        isAuthenticated: false,
        errorMessage: action.payload,
      };
    case "LOGOUT_USER":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        errorMessage: "",
      };
    case "REHYDRATE_USER":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    case "RESET_SUCCESS":
      return {
        ...state,
        resetMessage: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
