import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedal } from "@fortawesome/free-solid-svg-icons";

function Leaderboard() {
  const [users, setUsers] = useState([]);
  const apiBaseURL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem("userToken");

  useEffect(() => {
    async function fetchUsers() {
      const response = await axios.get(`${apiBaseURL}/users/adminview`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const sortedUsers = response.data
        .filter((user) => user.feiji > 0)
        .sort((a, b) => b.feiji - a.feiji);
      setUsers(sortedUsers);
    }
    fetchUsers();
  }, []);

  return (
    <div className="container mt-5">
      <h2 className="mb-4 text-center">Feiji Leaderboard</h2>

      {users.length >= 3 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "50px",
          }}
        >
          <div
            style={{
              width: "120px",
              margin: "0 20px",
              textAlign: "center",
              background: "#E0E0E0",
              padding: "10px",
              borderRadius: "10px",
              marginTop: "60px",
            }}
          >
            <div
              style={{
                padding: "30px 10px",
                background: "silver",
                borderRadius: "10px",
              }}
            >
              <FontAwesomeIcon
                icon={faMedal}
                style={{ color: "white", fontSize: "24px" }}
              />
              <p>{users[1].username}</p>
              <p>{users[1].feiji}</p>
            </div>
            <div style={{ marginTop: "10px" }}>2nd</div>
          </div>
          <div
            style={{
              width: "140px",
              margin: "0 20px",
              textAlign: "center",
              background: "#DAA520",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <div
              style={{
                padding: "40px 10px",
                background: "gold",
                borderRadius: "10px",
              }}
            >
              <FontAwesomeIcon
                icon={faMedal}
                style={{ color: "white", fontSize: "28px" }}
              />
              <p>{users[0].username}</p>
              <p>{users[0].feiji}</p>
            </div>
            <div style={{ marginTop: "10px" }}>1st</div>
          </div>
          <div
            style={{
              width: "120px",
              margin: "0 20px",
              textAlign: "center",
              background: "#8B4513",
              padding: "10px",
              borderRadius: "10px",
              marginTop: "60px",
            }}
          >
            <div
              style={{
                padding: "30px 10px",
                background: "#cd7f32",
                borderRadius: "10px",
              }}
            >
              <FontAwesomeIcon
                icon={faMedal}
                style={{ color: "white", fontSize: "24px" }}
              />
              <p>{users[2].username}</p>
              <p>{users[2].feiji}</p>
            </div>
            <div style={{ marginTop: "10px" }}>3rd</div>
          </div>
        </div>
      )}

      <table className="table table-striped">
        <thead>
          <tr>
            <th>Rank</th>
            <th>Name</th>
            <th>Feiji</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={user._id}>
              <td>{index + 1}</td>
              <td>{user.username}</td>
              <td>{user.feiji}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Leaderboard;
