import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authenticateUser } from "./../../actions/authActions";
import { useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";

function Login() {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  console.log(isAuthenticated);
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);
  const [formData, setFormData] = useState({
    phone: "",
    countryCode: "60",
    password: "",
  });

  const [responseMessage] = useState({
    type: "", // "success" or "error"
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.auth.errorMessage);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.phone.startsWith(formData.countryCode)) {
      var fullPhoneNumber = formData.countryCode + formData.phone;
      formData.phone = fullPhoneNumber;
    }

    dispatch(authenticateUser(formData, navigate));
  };
  const navigateToOtpLogin = async () => {
    navigate("/loginotp");
  };
  const navigateToForgotPassword = async () => {
    navigate("/resetpassword");
  };
  const navigateToRegister = async () => {
    navigate("/register");
  };
  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <Card>
            <Card.Header as="h3" className="text-center bg-primary text-white">
              Account Login
            </Card.Header>
            <Card.Body>
              <form onSubmit={handleSubmit} className="card p-4">
                <div className="mb-3 row">
                  <label className="form-label col-sm-2 col-form-label">
                    Phone
                  </label>
                  <div className="col-sm-2">
                    <select
                      name="countryCode"
                      onChange={handleChange}
                      value={formData.countryCode}
                      className="form-control"
                      required
                    >
                      <option value="60">+60 (Malaysia)</option>
                      <option value="65">+65 (Singapore)</option>{" "}
                    </select>
                  </div>
                  <div className="col-sm-8">
                    <input
                      name="phone"
                      type="phone"
                      className="form-control"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Enter Phone Number"
                      required
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">Password</label>
                  <input
                    name="password"
                    type="password"
                    className="form-control"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Password"
                    required
                  />
                </div>
                <button type="submit" className="btn btn-success w-100">
                  Submit
                </button>
                <div className="d-flex justify-content-between">
                  <a href="#" onClick={() => navigateToRegister()}>
                    No Account Yet? Click here to Register
                  </a>
                  <a href="#" onClick={() => navigateToForgotPassword()}>
                    Forgot Password?
                  </a>
                </div>
                <div
                  style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  OR
                </div>{" "}
                <button
                  className="btn btn-secondary"
                  onClick={() => navigateToOtpLogin()}
                >
                  Login with Phone Instead?
                </button>
              </form>
            </Card.Body>
          </Card>
          {errorMessage && (
            <div className="mt-4 alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}

          {responseMessage.message && (
            <div
              className={`mt-4 alert alert-${
                responseMessage.type === "success" ? "success" : "danger"
              }`}
              role="alert"
            >
              {responseMessage.message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
