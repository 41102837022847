import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userLogout } from "../actions/authActions";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FaBars, FaUserCircle, FaSignOutAlt, FaEdit } from "react-icons/fa";
import axios from "axios";

const Nav = styled.nav`
  background: linear-gradient(90deg, #3498db 0%, #8e44ad 100%);
  padding: 0.8rem 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const Brand = styled(Link)`
  color: #ffffff;
  font-size: 2.2rem;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    color: #f1c40f;
    transform: scale(1.05);
  }
`;

const NavLink = styled(Link)`
  color: #ffffff;
  padding: 0.8rem 1.5rem;
  text-decoration: none;
  border-radius: 20px;
  transition: all 0.3s ease;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transform: scale(1.1);
  }
`;

const UserText = styled.span`
  color: #ffffff;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const MenuButton = styled.button`
  background: none;
  border: none;
  display: none;
  color: #ffffff;
  font-size: 2rem;
  @media (max-width: 768px) {
    display: block;
  }
`;

const StyledButton = styled.button`
  background-color: #f39c12;
  color: #ffffff;
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 20px;
  transition: all 0.3s ease;
  &:hover {
    background-color: #d35400;
    transform: translateY(-3px);
  }
`;

const MenuItems = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  @media (max-width: 768px) {
    display: ${(props) => (props.isOpen ? "flex" : "none")};
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
`;

function Navbar() {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth?.user);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const apiBaseURL = process.env.REACT_APP_BACKEND_URL;
  const [avatarUrl, setAvatarUrl] = useState();
  const token = localStorage.getItem("userToken");

  const handleLogout = () => {
    dispatch(userLogout(navigate));
  };

  useEffect(() => {
    async function fetchUserProfile() {
      try {
        const response = await axios.get(
          `${apiBaseURL}/users/${currentUser.userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // const { avatar } = response.data;
        // localStorage.setItem("avatarUrl", avatar); // Store avatar URL in localStorage
        //setAvatarUrl(avatar);
      } catch (error) {
        console.error("Failed to fetch user profile:", error);
      }
    }

    if (currentUser) {
      fetchUserProfile();
    }
  }, [token, avatarUrl]);

  // Load avatar from local storage if available
  // useEffect(() => {
  //   const storedAvatar = localStorage.getItem("avatarUrl");
  //   if (storedAvatar) {
  //     setAvatarUrl(storedAvatar);
  //   }
  // }, [token, avatarUrl]);

  useEffect(() => {
    const handleUnload = () => {
      setIsOpen(false);
    };
    window.addEventListener("beforeunload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  return (
    <Nav>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Brand to="/">EZ-Book</Brand>
        <MenuButton onClick={() => setIsOpen(!isOpen)}>
          <FaBars size={30} color="#ecf0f1" />
        </MenuButton>
      </div>
      <MenuItems isOpen={isOpen}>
        <NavLink to="/courts">All Courts</NavLink>
        {(currentUser?.role === "pro" ||
          currentUser?.role === "admin" ||
          currentUser?.role === "user") && (
          <NavLink to="/own-courts">Own Courts</NavLink>
        )}
        {(currentUser?.role === "pro" || currentUser?.role === "admin") && (
          <NavLink to="/courts/create">Create Court</NavLink>
        )}
        <NavLink to="/leaderboard">Leaderboard</NavLink>

        <NavLink to="/contact">Contact</NavLink>
        {currentUser ? (
          <>
            <UserText>
              {/* {avatarUrl && (
                <img
                  style={{
                    width: "40px", // Changed
                    height: "40px", // Changed
                    borderRadius: "50%",
                  }}
                  src={`data:image/jpeg;base64,${avatarUrl}`}
                  alt={`${currentUser.username}'s avatar`}
                />
              )} */}
              Welcome, {currentUser.username}
            </UserText>
            <NavLink to={`/profile/update/${currentUser.userId}`}>
              <FaEdit size={20} color="#ecf0f1" /> Update Profile
            </NavLink>
            <StyledButton bgColor="#e74c3c" onClick={handleLogout}>
              <FaSignOutAlt size={20} color="#ecf0f1" /> Logout
            </StyledButton>
          </>
        ) : (
          <NavLink to="/login">Login</NavLink>
        )}
      </MenuItems>
    </Nav>
  );
}

export default Navbar;
