import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import styles from "./CourtDetail.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMars, faVenus } from "@fortawesome/free-solid-svg-icons";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import Modal from "react-modal";
import { faMale, faFemale } from "@fortawesome/free-solid-svg-icons";

function CourtDetail() {
  const apiBaseURL = process.env.REACT_APP_BACKEND_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [court, setCourt] = useState({ list_of_players: [] });
  const [tempCourt, setTempCourt] = useState({ list_of_players: [] });
  const [avatarModalIsOpen, setAvatarModalIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const { courtId } = useParams();
  const [isSignedUp, setIsSignedUp] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [countOfPlayer, setCountOfPlayer] = useState(0);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [enteredPassword, setEnteredPassword] = useState("");
  const [shouldPoll, setShouldPoll] = useState(false); // Add this state variable

  const verifyPassword = () => {
    // In reality, you'd want to verify the password with your backend API
    handleVerify(courtId);
  };
  const handleCancelModal = () => {
    setShowPasswordModal(false);
    navigate("/courts");
  };
  const handleAvatarClick = (user) => {
    setSelectedUser(user);
    setAvatarModalIsOpen(true);
  };
  const handleVerify = (courtId) => {
    axios
      .post(
        `${apiBaseURL}/courts/verify/${courtId}`,
        {
          roomPassword: enteredPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Make sure to pass your JWT token here
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("shouldPoll", "true");
          setShowPasswordModal(false);
          setTimeout(() => {
            navigate(`/courts/detail/${courtId}`); // Redirects to a court detail page, adjust route as necessary
          }, 1000);
          alert("Successfully verified!");
        }
      })
      .catch((error) => {
        if (error?.response.status === 403) {
          alert("Incorrect password. Please try again.");
        } else if (error.response.status === 500) {
          navigate(`/courts`);
        } else {
          console.log(error.message);
        }
      });
  };
  const handleModalClose = () => {
    showPasswordModal(false);
    setEnteredPassword("");
  };
  const token = localStorage.getItem("userToken"); // or wherever you store it
  const currentUser = useSelector((state) => state.auth?.user);
  const navigate = useNavigate();
  const [comment, setComment] = useState("");
  const endOfChatRef = useRef(null);

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    localStorage.setItem("shouldPoll", "true");

    if (endOfChatRef.current) {
      endOfChatRef.current.scrollIntoView({ behavior: "smooth" });
    }
    const fetchCourtData = async () => {
      try {
        if (localStorage.getItem("shouldPoll") === "false") {
          return;
        }
        const response = await axios.post(
          `${apiBaseURL}/courts/${courtId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCourt(response.data);

        const isUserSignedUp = response.data.list_of_players.some(
          (player) =>
            String(player.user._id) === String(currentUser.userId) &&
            player.booking_status === "confirmed"
        );

        setIsSignedUp(isUserSignedUp);
        setCountOfPlayer(
          response.data.list_of_players.filter(
            (user) =>
              user.booking_status === "confirmed" ||
              user.booking_status === "completed"
          ).length
        );

        const isCompleted = response.data.list_of_players.some(
          (player) =>
            String(player.user._id) === String(currentUser.userId) &&
            player.booking_status === "completed"
        );

        setIsCompleted(isCompleted);
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 403) {
          alert("You are not authorized to view this court.");
          localStorage.setItem("shouldPoll", "false");
          setShowPasswordModal(true);
        }
        if (error?.response?.status === 409) {
          alert("You have been banned from accessing this court.");
          navigate("/courts");
        }
        if (error?.response?.status === 500) {
          navigate(`/courts`);
        }
      }
    };

    // Fetch the data immediately
    fetchCourtData();

    // Then poll every 5 seconds
    const intervalId = setInterval(fetchCourtData, 5000);

    // Don't forget to clear the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  }, [courtId, currentUser]);

  const handleAddComment = (e) => {
    e.preventDefault();
    try {
      setComment(""); // Reset comment box

      setIsLoading(true);
      if (!comment.trim()) return; // prevents sending empty or whitespace-only messages
      axios
        .post(
          `${apiBaseURL}/courts/${courtId}/add-comment`,
          { comment },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          // Refresh court data to show the new comment
          setCourt(response.data);
          if (endOfChatRef.current) {
            endOfChatRef.current.scrollIntoView({ behavior: "smooth" });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleSignUp = () => {
    try {
      setIsLoading(true);
      axios
        .post(
          `${apiBaseURL}/courts/signup/${courtId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`, // Make sure to pass your JWT token here
            },
          }
        )
        .then((response) => {
          setCourt(response.data); // Assuming the updated court data is returned from the backend
          setIsSignedUp(true);
          alert("Successfully signed up!");
        })
        .catch((error) => {
          console.error(
            "Error signing up:",
            error.response?.data?.message || "An error occurred."
          );
          alert(
            error.response?.data?.message ||
              "An error occurred while signing up."
          );
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleFeiji = () => {
    try {
      setIsLoading(true);
      axios
        .put(
          `${apiBaseURL}/courts/cancel-booking/${courtId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`, // Make sure to pass your JWT token here
            },
          }
        )
        .then((response) => {
          setCourt(response.data); // Assuming the updated court data is returned from the backend
          alert("Successfully cancelled signup!");
          setIsSignedUp(false);
        })
        .catch((error) => {
          console.error(
            "Error cancelling signup:",
            error.response?.data?.message || "An error occurred."
          );
          alert(
            error.response?.data?.message ||
              "An error occurred while cancelling signup."
          );
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  function StatusPanel({ currentStatus, onUpdateStatus }) {
    const statuses = ["open", "playing", "payment", "completed", "cancelled"];

    return (
      <div className="mt-3 card">
        <div className="card-header">
          <h5>Quick Status Update</h5>
        </div>
        <div className="card-body">
          {/* For large screens */}
          <div
            className="btn-group d-none d-md-block"
            role="group"
            aria-label="Status buttons"
          >
            {statuses.map((status) => (
              <button
                key={status}
                className={`btn btn-lg ${
                  currentStatus === status
                    ? "btn-primary"
                    : "btn-outline-secondary"
                }`}
                onClick={() => onUpdateStatus(status)}
              >
                {status}
              </button>
            ))}
          </div>

          {/* For small screens */}
          <div
            className="btn-group d-md-none"
            role="group"
            aria-label="Status buttons"
          >
            {statuses.map((status) => (
              <button
                key={status}
                className={`btn btn-sm ${
                  currentStatus === status
                    ? "btn-primary"
                    : "btn-outline-secondary"
                }`}
                onClick={() => onUpdateStatus(status)}
              >
                {status}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  }
  const updateStatus = (newStatus) => {
    // Axios call to update the status
    axios
      .put(
        `${apiBaseURL}/courts/edit/${courtId}`,
        { status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`, // pass your JWT token
          },
        }
      )
      .then((response) => {
        setCourt(response.data);
        alert(`Status updated to: ${newStatus}`);
      })
      .catch((error) => {
        console.error(
          "Error updating status:",
          error.response?.data?.message || "An error occurred."
        );
        alert(
          error.response?.data?.message ||
            "An error occurred while updating status."
        );
      });
  };
  async function updateUserBooking(courtId, playerId, newStatus) {
    try {
      const response = await axios.put(
        `${apiBaseURL}/courts/updateuserbooking/${courtId}`,
        {
          PlayerId: playerId,
          NewStatus: newStatus,
        },
        {
          headers: {
            "Content-Type": "application/json",
            // Include JWT token in headers if required for authentication
            Authorization: `Bearer ${token}`, // pass your JWT token
          },
        }
      );
      setCourt(response.data); // Assuming the updated court data is returned from the backend

      return response.data;
    } catch (error) {
      console.error(
        "Error updating user booking:",
        error.response.data.message || error.message
      );
      throw error;
    }
  }
  function handleRemove(playerId) {
    const isConfirmed = window.confirm(
      "Are you sure you want to remove this player?"
    );
    if (isConfirmed) {
      const newStatus = "removed"; // Assuming 'Removed' as the status for removing a player

      updateUserBooking(courtId, playerId, newStatus)
        .then((data) => {})
        .catch((error) => {
          console.error("Error removing player:", error.message);
          // Handle error, like displaying an error message to the user
        });
    }
  }
  const copyLinkToClipboard = (courtId) => {
    const link = `${window.location.origin}/courts/detail/${courtId}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        alert("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy link: ", err);
      });
  };

  const handleShare = (courtId) => {
    const link = `${window.location.origin}/courts/detail/${courtId}`;

    if (navigator.share) {
      navigator
        .share({
          title: "Check out this court!",
          text: "Here is a link to a court you might be interested in:",
          url: link,
        })
        .catch((error) => console.log("Error sharing", error));
    } else {
      copyLinkToClipboard(courtId);
    }
  };
  function handleBan(playerId) {
    const isConfirmed = window.confirm(
      "Are you sure you want to ban this player?"
    );
    if (isConfirmed) {
      const newStatus = "banned"; // Assuming 'Banned' as the status for banning a player

      updateUserBooking(courtId, playerId, newStatus)
        .then((data) => {})
        .catch((error) => {
          // Handle error, like displaying an error message to the user
        });
    }
  }
  function handleUnBan(playerId) {
    const isConfirmed = window.confirm(
      "Are you sure you want to unban this player?"
    );
    if (isConfirmed) {
      const newStatus = "confirmed"; // Assuming 'Banned' as the status for banning a player

      updateUserBooking(courtId, playerId, newStatus)
        .then((data) => {})
        .catch((error) => {
          // Handle error, like displaying an error message to the user
        });
    }
  }
  function handleUnRemove(playerId) {
    const isConfirmed = window.confirm(
      "Are you sure you want to un-removed this player?"
    );
    if (isConfirmed) {
      const newStatus = "confirmed"; // Assuming 'Banned' as the status for banning a player

      updateUserBooking(courtId, playerId, newStatus)
        .then((data) => {})
        .catch((error) => {
          // Handle error, like displaying an error message to the user
        });
    }
  }
  function handleUnFeiji(playerId) {
    const isConfirmed = window.confirm(
      "Are you sure you want to unfeiji this player?"
    );
    if (isConfirmed) {
      const newStatus = "confirmed"; // Assuming 'Banned' as the status for banning a player

      updateUserBooking(courtId, playerId, newStatus)
        .then((data) => {})
        .catch((error) => {
          // Handle error, like displaying an error message to the user
        });
    }
  }
  function handleComplete(playerId) {
    const newStatus = "completed"; // Assuming 'Banned' as the status for banning a player

    updateUserBooking(courtId, playerId, newStatus)
      .then((data) => {
        setCourt(data);
      })
      .catch((error) => {
        // Handle error, like displaying an error message to the user
      });
  }
  function handleUnComplete(playerId) {
    const newStatus = "confirmed"; // Assuming 'Banned' as the status for banning a player

    updateUserBooking(courtId, playerId, newStatus)
      .then((data) => {
        setCourt(data);
      })
      .catch((error) => {
        // Handle error, like displaying an error message to the user
      });
  }
  function handleFeijiSpecial(playerId) {
    const isConfirmed = window.confirm(
      "Are you sure you want to feiji this player?"
    );
    if (isConfirmed) {
      const newStatus = "feiji";

      updateUserBooking(courtId, playerId, newStatus)
        .then((data) => {})
        .catch((error) => {
          // Handle error, like displaying an error message to the user
        });
    }
  }
  function formatDateTime(dateTimeString) {
    if (dateTimeString) {
      // Split date and time
      const [datePart, timePart] = dateTimeString.split("T");
      const [year, month, day] = datePart.split("-");
      let [hours, minutes] = timePart.split(":");

      // Convert to 12-hour format with AM/PM
      const amPm = parseInt(hours, 10) >= 12 ? "PM" : "AM";
      hours = (((parseInt(hours, 10) - 1) % 12) + 1).toString(); // Convert hour '0' to '12' and 24-hour to 12-hour format

      // Days of the week
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const dayOfWeek = days[new Date(datePart).getDay()];

      return `${year}-${month}-${day} ${hours}:${minutes} ${amPm} - ${dayOfWeek}`;
    }
  }
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      padding: "20px",
      backgroundColor: "#f9f9f9",
    },
    card: {
      width: "100%",
      maxWidth: "500px",
    },
  };
  if (!court || currentUser === null) {
    return (
      <div style={styles.container}>
        <Card style={styles.card}>
          <Card.Body>
            <Card.Title>Access Restricted</Card.Title>
            <Card.Text>Please sign in to view the court details.</Card.Text>
            <Button variant="primary" onClick={() => navigate("/login")}>
              Login
            </Button>
          </Card.Body>
        </Card>
      </div>
    );
  }
  return (
    <div className="container my-5">
      <div className="card shadow-lg">
        <div
          className="card-header"
          style={{ backgroundColor: "#2C3E50", color: "white" }}
        >
          <h3 className="text-center mb-1">{court.name}</h3>
          <div className="text-center">
            Court ID: {court._id}
            <button
              onClick={() => navigator.clipboard.writeText(court._id)}
              style={{
                marginLeft: "10px",
                backgroundColor: "transparent",
                border: "1px solid white",
                color: "white",
                padding: "2px 6px",
                borderRadius: "3px",
                cursor: "pointer",
              }}
              title="Copy Court ID"
            >
              Copy
            </button>
          </div>
        </div>

        <div className="card-body">
          <div className="row mb-3">
            <div className="col-md-6">
              <p className="mb-2">
                <strong>Created By:</strong>
                {/* <img
                  style={{
                    width: "80px", // Changed
                    height: "80px", // Changed
                    borderRadius: "50%",
                  }}
                  src={`data:image/jpeg;base64,${court.created_by?.avatar}`}
                  alt={`${court.created_by?.username}'s avatar`}
                  onClick={() => handleAvatarClick(court.created_by)}
                />
                {"  "} */}
                {"  "}
                <strong>{court?.created_by?.username}</strong>
              </p>
              <p>
                <strong>Location:</strong>{" "}
                {!court.location || !court.location.length
                  ? "-"
                  : court.location[0].toUpperCase() + court.location.slice(1)}
              </p>
              <p>
                <strong>Start Time:</strong> {formatDateTime(court.start_time)}
              </p>
              <p>
                <strong>End Time:</strong> {formatDateTime(court.end_time)}
              </p>
              <div
                style={{
                  marginBottom: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <strong style={{ marginRight: "10px" }}>Players:</strong>
                <span style={{ marginRight: "20px" }}>
                  {
                    court.list_of_players.filter(
                      (player) =>
                        player.booking_status === "confirmed" ||
                        player.booking_status === "completed"
                    ).length
                  }
                  /{court.maximum_players}
                </span>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "20px",
                    padding: "4px 12px",
                    margin: "0 5px",
                    backgroundColor: "#e6f7ff",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faMale}
                    style={{ color: "#3498db", marginRight: "8px" }}
                  />
                  {
                    court.list_of_players.filter(
                      (player) =>
                        (player.booking_status === "confirmed" ||
                          player.booking_status === "completed") &&
                        player.user.gender === "male"
                    ).length
                  }
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "20px",
                    padding: "4px 12px",
                    margin: "0 5px",
                    backgroundColor: "#fff0f0",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFemale}
                    style={{ color: "#e74c3c", marginRight: "8px" }}
                  />
                  {
                    court.list_of_players.filter(
                      (player) =>
                        (player.booking_status === "confirmed" ||
                          player.booking_status === "completed") &&
                        player.user.gender === "female"
                    ).length
                  }
                </div>
              </div>
              <p>
                <strong>Description:</strong>{" "}
                {!court.description || !court.description.length
                  ? "-"
                  : court.description[0].toUpperCase() +
                    court.description.slice(1)}
              </p>
            </div>
            <div className="col-md-6">
              {/* <p>
                <strong>Number of Courts:</strong>{" "}
                {court.courts_num?.filter((num) => num !== "invalid").length}
              </p> */}
              <p>
                <strong>Court Numbers (Separated by @): </strong>
                {court.courts_num
                  ?.filter((num) => num !== "invalid")
                  .join(" @")}
              </p>
              <p>
                <strong>Pricing Mode:</strong>{" "}
                {!court.pricing_mode || !court.pricing_mode.length
                  ? "-"
                  : court.pricing_mode[0].toUpperCase() +
                    court.pricing_mode.slice(1)}
              </p>
              <p>
                {court.male_price && court.male_price !== 0 && (
                  <>
                    <strong>Male Price:</strong> ${court.male_price}
                  </>
                )}
              </p>
              <p>
                {court.female_price && court.female_price !== 0 && (
                  <>
                    <strong>Female Price:</strong> ${court.female_price}
                  </>
                )}
              </p>

              <p>
                <strong>Skill Level: </strong>
                {!court.skill_level || !court.skill_level.length
                  ? "-"
                  : court.skill_level[0].toUpperCase() +
                    court.skill_level.slice(1)}
              </p>
              <p>
                <strong>Status: </strong>
                {!court.status || !court.status.length
                  ? "-"
                  : court.status[0].toUpperCase() + court.status.slice(1)}
              </p>
            </div>
          </div>

          {(court.created_by?._id === currentUser.userId ||
            currentUser.role === "admin") && (
            <button
              style={{ marginRight: "20px" }} // adding right margin to the first button
              className="btn btn-warning mt-3 mb-3 mr-2"
              onClick={() => navigate(`/courts/edit/${courtId}`)}
            >
              Setting
            </button>
          )}

          {court.status === "open" &&
            isCompleted === false &&
            (isSignedUp ? (
              <button
                style={{ marginRight: "20px" }} // adding right margin to the first button
                className="btn btn-danger mt-3 mb-3"
                onClick={handleFeiji}
                disabled={isLoading}
              >
                Feiji (Cancel Signup)
              </button>
            ) : (
              countOfPlayer < court.maximum_players && (
                <button
                  style={{ marginRight: "20px" }} // adding right margin to the first button
                  className="btn btn-success mt-3 mb-3"
                  onClick={handleSignUp}
                  disabled={isLoading}
                >
                  Sign Up
                </button>
              )
            ))}
          <button
            onClick={() => handleShare(court._id)}
            className="btn btn-secondary mt-3 mb-3"
            style={{ marginRight: "20px" }} // added inline style here
          >
            Share
          </button>
          <button
            onClick={() => copyLinkToClipboard(court._id)}
            className="btn btn-info mt-3 mb-3"
          >
            Copy Link
          </button>
        </div>
      </div>
      {((currentUser.role === "pro" &&
        currentUser.username === court.created_by?.username) ||
        currentUser.role === "admin") && (
        <StatusPanel
          currentStatus={court.status}
          onUpdateStatus={updateStatus}
        />
      )}
      {/* Player Section */}
      <div className="card mt-4">
        <div className="card-header bg-info text-white">
          <h4 className="mb-0">List of Players</h4>
        </div>
        <div className="card-body">
          <div className="row mb-4">
            <div className="col-md-6">
              {Array.from({ length: Math.ceil(court.maximum_players / 2) }).map(
                (_, index) => {
                  const confirmedPlayers = court.list_of_players?.filter(
                    (player) =>
                      player.booking_status === "confirmed" ||
                      player.booking_status === "completed"
                  );

                  const player = confirmedPlayers?.[index];
                  let genderIcon = null;
                  if (player && player.user) {
                    if (player.user.gender === "male") {
                      genderIcon = (
                        <FontAwesomeIcon
                          icon={faMars}
                          className="text-primary ml-2"
                          style={{ marginLeft: "10px" }}
                        />
                      );
                    } else if (player.user.gender === "female") {
                      genderIcon = (
                        <FontAwesomeIcon
                          icon={faVenus}
                          className="text-pink ml-2"
                          style={{ color: "#D60073", marginLeft: "10px" }}
                        />
                      );
                    }
                  }
                  return (
                    <div
                      key={index}
                      className="card mb-2"
                      style={{
                        backgroundColor:
                          player?.booking_status === "confirmed"
                            ? "lightyellow"
                            : player?.booking_status === "completed"
                            ? "lightgreen"
                            : "inherit",
                      }}
                    >
                      <div className="card-body">
                        <h6
                          className="card-title"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {index + 1}.
                          </span>
                          <span
                            style={{ minWidth: "150px", textAlign: "left" }}
                          >
                            {player?.user?.username || "No Player"}
                            {((currentUser.role === "pro" &&
                              currentUser.username ===
                                court?.created_by?.username) ||
                              currentUser.role === "admin") &&
                              player?.user?.phone && (
                                <span> ({player?.user?.phone})</span>
                              )}
                          </span>{" "}
                          {genderIcon && (
                            <span
                              style={{
                                marginLeft: "0.2rem",
                                marginRight: "0.2rem",
                              }}
                            >
                              {genderIcon}
                            </span>
                          )}
                          {player?.user?.skill_level_default && (
                            <span
                              style={{
                                background: "#f2f2f2",
                                padding: "0.2rem 0.4rem",
                                borderRadius: "0.2rem",
                              }}
                            >
                              {capitalizeFirstLetter(
                                player.user.skill_level_default
                              )}
                            </span>
                          )}
                        </h6>
                        {((currentUser.role === "admin" &&
                          player?.user?.username &&
                          player?.user?.username !== currentUser?.username) ||
                          (currentUser.username ===
                            court?.created_by?.username &&
                            currentUser.role === "pro" &&
                            player?.user?.username !==
                              court?.created_by?.username &&
                            player?.user?.role !== "admin" &&
                            player?.user?.username)) && (
                          <div>
                            {player.booking_status === "confirmed" ? (
                              <button
                                className="btn btn-success mr-2"
                                style={{ marginRight: "20px" }}
                                onClick={() => handleComplete(player.user._id)}
                              >
                                Complete Payment
                              </button>
                            ) : null}

                            {player.booking_status === "completed" ? (
                              <button
                                className="btn btn-danger mr-2"
                                style={{ marginRight: "20px" }}
                                onClick={() =>
                                  handleUnComplete(player.user._id)
                                }
                              >
                                Undo Payment
                              </button>
                            ) : null}

                            <button
                              className="btn btn-warning mr-2"
                              style={{ marginRight: "20px" }}
                              onClick={() => handleRemove(player.user._id)}
                            >
                              Remove (No Penalty)
                            </button>
                            <button
                              className="btn btn-danger mr-2"
                              style={{ marginRight: "20px" }}
                              onClick={() =>
                                handleFeijiSpecial(player.user._id)
                              }
                            >
                              Feiji (Penalty)
                            </button>
                            <button
                              className="btn btn-danger "
                              onClick={() => handleBan(player.user._id)}
                            >
                              Ban
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }
              )}
            </div>

            <div className="col-md-6">
              {Array.from({
                length: Math.floor(court.maximum_players / 2),
              }).map((_, index) => {
                const playerIndex =
                  Math.ceil(court.maximum_players / 2) + index;
                const confirmedPlayers = court.list_of_players?.filter(
                  (player) =>
                    player.booking_status === "confirmed" ||
                    player.booking_status === "completed"
                );

                const player = confirmedPlayers?.[playerIndex];
                let genderIcon = null;
                if (player && player.user) {
                  if (player.user.gender === "male") {
                    genderIcon = (
                      <FontAwesomeIcon
                        icon={faMars}
                        className="text-primary ml-2"
                        style={{ marginLeft: "10px" }}
                      />
                    );
                  } else if (player.user.gender === "female") {
                    genderIcon = (
                      <FontAwesomeIcon
                        icon={faVenus}
                        className="text-pink ml-2"
                        style={{ color: "#D60073", marginLeft: "10px" }}
                      />
                    );
                  }
                }
                return (
                  <div
                    key={index}
                    className="card mb-2"
                    style={{
                      backgroundColor:
                        player?.booking_status === "confirmed"
                          ? "lightyellow"
                          : player?.booking_status === "completed"
                          ? "lightgreen"
                          : "inherit",
                    }}
                  >
                    <div className="card-body">
                      <h6
                        className="card-title"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5rem",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {playerIndex + 1}.
                        </span>
                        <span style={{ minWidth: "150px", textAlign: "left" }}>
                          {player?.user?.username || "No Player"}
                          {((currentUser.role === "pro" &&
                            currentUser.username ===
                              court?.created_by?.username) ||
                            currentUser.role === "admin") &&
                            player?.user?.phone && (
                              <span> ({player?.user?.phone})</span>
                            )}
                        </span>{" "}
                        {genderIcon && (
                          <span
                            style={{
                              marginLeft: "0.2rem",
                              marginRight: "0.2rem",
                            }}
                          >
                            {genderIcon}
                          </span>
                        )}
                        {player?.user?.skill_level_default && (
                          <span
                            style={{
                              background: "#f2f2f2",
                              padding: "0.2rem 0.4rem",
                              borderRadius: "0.2rem",
                            }}
                          >
                            {capitalizeFirstLetter(
                              player.user.skill_level_default
                            )}
                          </span>
                        )}
                      </h6>
                      {((currentUser.role === "admin" &&
                        player?.user?.username &&
                        player?.user?.username !== currentUser?.username) ||
                        (currentUser.username === court?.created_by?.username &&
                          currentUser.role === "pro" &&
                          player?.user?.username !==
                            court?.created_by?.username &&
                          player?.user?.role !== "admin" &&
                          player?.user?.username)) && (
                        <div>
                          {player.booking_status === "confirmed" ? (
                            <button
                              className="btn btn-success mr-2"
                              style={{ marginRight: "20px" }}
                              onClick={() => handleComplete(player.user._id)}
                            >
                              Complete Payment
                            </button>
                          ) : null}

                          {player.booking_status === "completed" ? (
                            <button
                              className="btn btn-danger mr-2"
                              style={{ marginRight: "20px" }}
                              onClick={() => handleUnComplete(player.user._id)}
                            >
                              Undo Payment
                            </button>
                          ) : null}

                          <button
                            className="btn btn-warning mr-2"
                            style={{ marginRight: "20px" }}
                            onClick={() => handleRemove(player.user._id)}
                          >
                            Remove (No Penalty)
                          </button>
                          <button
                            className="btn btn-danger mr-2"
                            style={{ marginRight: "20px" }}
                            onClick={() => handleFeijiSpecial(player.user._id)}
                          >
                            Feiji (Penalty)
                          </button>
                          <button
                            className="btn btn-danger "
                            onClick={() => handleBan(player.user._id)}
                          >
                            Ban
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/* Unlisted Section */}
      {((currentUser.role === "pro" &&
        currentUser.username === court.created_by?.username) ||
        currentUser.role === "admin") && (
        <>
          {court.list_of_players?.filter((player) =>
            ["feiji", "removed", "banned", "cancelled"].includes(
              player.booking_status
            )
          ).length > 0 && (
            <div className="card mt-4">
              <div className="card-header bg-info text-white">
                <h4 className="mb-0">
                  Unlisted Players(Feiji, Removed, Banned)
                </h4>
              </div>
              <div className="card-body">
                <div className="row mb-4">
                  <div className="col-md-12">
                    {/* Filter players based on their booking_status */}
                    {court.list_of_players
                      ?.filter((player) =>
                        ["feiji", "removed", "banned", "cancelled"].includes(
                          player.booking_status
                        )
                      )
                      .map((player, index) => {
                        let genderIcon = null;
                        if (player && player.user) {
                          if (player.user.gender === "male") {
                            genderIcon = (
                              <FontAwesomeIcon
                                icon={faMars}
                                className="text-primary ml-2"
                              />
                            );
                          } else if (player.user.gender === "female") {
                            genderIcon = (
                              <FontAwesomeIcon
                                icon={faVenus}
                                style={{ color: "pink", marginLeft: "10px" }}
                              />
                            );
                          }
                        }
                        return (
                          <div key={index} className="card mb-2">
                            <div className="card-body">
                              <h6 className="card-title">
                                {index + 1}.{" "}
                                {/* {player && player.user ? (
                                  <img
                                    style={{
                                      width: "80px", // Changed
                                      height: "80px", // Changed
                                      borderRadius: "50%",
                                      marginRight: "20px",
                                      marginLeft: "10px",
                                    }}
                                    src={`data:image/jpeg;base64,${player.user.avatar}`}
                                    alt={`${player.user.username}'s avatar`}
                                    onClick={() =>
                                      handleAvatarClick(player.user)
                                    }
                                  />
                                ) : (
                                  <div></div>
                                )} */}
                                {player && player.user
                                  ? player.user.username
                                  : "No Player"}
                                {genderIcon}
                              </h6>
                              {((currentUser.role === "admin" &&
                                player?.user?.username &&
                                player?.user?.username !==
                                  currentUser?.username) ||
                                (currentUser.username ===
                                  court?.created_by?.username &&
                                  currentUser.role === "pro" &&
                                  player?.user?.username !==
                                    court?.created_by?.username &&
                                  player?.user?.role !== "admin" &&
                                  player?.user?.username)) && (
                                <div>
                                  {player.booking_status === "cancelled" ? (
                                    <button
                                      className="btn btn-warning mr-2"
                                      style={{ marginRight: "20px" }}
                                      onClick={() =>
                                        handleUnRemove(player.user._id)
                                      }
                                    >
                                      Un-Remove
                                    </button>
                                  ) : null}
                                  {player.booking_status === "removed" ? (
                                    <button
                                      className="btn btn-warning mr-2"
                                      style={{ marginRight: "20px" }}
                                      onClick={() =>
                                        handleUnRemove(player.user._id)
                                      }
                                    >
                                      Un-Remove
                                    </button>
                                  ) : null}

                                  {player.booking_status === "banned" ? (
                                    <button
                                      className="btn btn-danger "
                                      onClick={() =>
                                        handleUnBan(player.user._id)
                                      }
                                    >
                                      Un-Ban
                                    </button>
                                  ) : null}
                                  {player.booking_status === "feiji" ? (
                                    <button
                                      className="btn btn-danger mr-2"
                                      style={{ marginRight: "20px" }}
                                      onClick={() =>
                                        handleUnFeiji(player.user._id)
                                      }
                                    >
                                      UnFeiji
                                    </button>
                                  ) : null}
                                </div>
                              )}{" "}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {/* {((currentUser.role === "pro" &&
        currentUser.username === court.created_by?.username) ||
        currentUser.role === "admin") && (
        <div className="card mt-4">
          <div className="card-header bg-info text-white">
            <h4 className="mb-0">Unlisted Players(Feiji,Removed,Banned)</h4>
          </div>
          <div className="card-body">
            <div className="row mb-4">
              <div className="col-md-6">
                {Array.from({
                  length: Math.ceil(court.maximum_players / 2),
                }).map((_, index) => {
                  const confirmedPlayers = court.list_of_players?.filter(
                    (player) =>
                      player.booking_status === "feiji" ||
                      player.booking_status === "removed" ||
                      player.booking_status === "banned" ||
                      player.booking_status === "cancelled"
                  );

                  const player = confirmedPlayers?.[index];
                  let genderIcon = null;
                  if (player && player.user) {
                    if (player.user.gender === "male") {
                      genderIcon = (
                        <FontAwesomeIcon
                          icon={faMars}
                          className="text-primary ml-2"
                          style={{ marginLeft: "10px" }}
                        />
                      );
                    } else if (player.user.gender === "female") {
                      genderIcon = (
                        <FontAwesomeIcon
                          icon={faVenus}
                          className="text-pink ml-2"
                          style={{ marginLeft: "10px" }}
                        />
                      );
                    }
                  }
                  return (
                    <div
                      key={index}
                      className="card mb-2"
                      style={{
                        backgroundColor:
                          player?.booking_status === "confirmed"
                            ? "lightyellow"
                            : player?.booking_status === "completed"
                            ? "lightgreen"
                            : "inherit",
                      }}
                    >
                      <div className="card-body">
                        <h6 className="card-title">
                          {index + 1}.{" "}
                          {player && player.user
                            ? player.user.username
                            : "No Player"}
                          {genderIcon}
                        </h6>
                        {((currentUser.role === "admin" &&
                          player?.user?.username &&
                          player?.user?.username !== currentUser?.username) ||
                          (currentUser.username ===
                            court?.created_by?.username &&
                            currentUser.role === "pro" &&
                            player?.user?.username !==
                              court?.created_by?.username &&
                            player?.user?.role !== "admin" &&
                            player?.user?.username)) && (
                          <div>
                            {player.booking_status === "removed" ? (
                              <button
                                className="btn btn-warning mr-2"
                                style={{ marginRight: "20px" }}
                                onClick={() => handleUnRemove(player.user._id)}
                              >
                                Un-Remove
                              </button>
                            ) : null}

                            {player.booking_status === "banned" ? (
                              <button
                                className="btn btn-danger "
                                onClick={() => handleUnBan(player.user._id)}
                              >
                                Un-Ban
                              </button>
                            ) : null}
                            {player.booking_status === "feiji" ? (
                              <button
                                className="btn btn-danger mr-2"
                                style={{ marginRight: "20px" }}
                                onClick={() => handleUnFeiji(player.user._id)}
                              >
                                UnFeiji
                              </button>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="col-md-6">
                {Array.from({
                  length: Math.floor(court.maximum_players / 2),
                }).map((_, index) => {
                  const playerIndex =
                    Math.ceil(court.maximum_players / 2) + index;
                  const confirmedPlayers = court.list_of_players?.filter(
                    (player) =>
                      player.booking_status === "feiji" ||
                      player.booking_status === "removed" ||
                      player.booking_status === "banned" ||
                      player.booking_status === "cancelled"
                  );

                  const player = confirmedPlayers?.[playerIndex];
                  let genderIcon = null;
                  if (player && player.user) {
                    if (player.user.gender === "male") {
                      genderIcon = (
                        <FontAwesomeIcon
                          icon={faMars}
                          className="text-primary ml-2"
                          style={{ marginLeft: "10px" }}
                        />
                      );
                    } else if (player.user.gender === "female") {
                      genderIcon = (
                        <FontAwesomeIcon
                          icon={faVenus}
                          className="text-pink ml-2"
                          style={{ marginLeft: "10px" }}
                        />
                      );
                    }
                  }
                  return (
                    <div
                      key={index}
                      className="card mb-2"
                      style={{
                        backgroundColor:
                          player?.booking_status === "confirmed"
                            ? "lightyellow"
                            : player?.booking_status === "completed"
                            ? "lightgreen"
                            : "inherit",
                      }}
                    >
                      <div className="card-body">
                        <h6 className="card-title">
                          {playerIndex + 1}.{" "}
                          {player && player.user
                            ? player.user.username
                            : "No Player"}
                          {genderIcon}
                        </h6>
                        {((currentUser.role === "admin" &&
                          player?.user?.username &&
                          player?.user?.username !== currentUser?.username) ||
                          (currentUser.username ===
                            court?.created_by?.username &&
                            currentUser.role === "pro" &&
                            player?.user?.username !==
                              court?.created_by?.username &&
                            player?.user?.role !== "admin" &&
                            player?.user?.username)) && (
                          <div>
                            {player.booking_status === "removed" ? (
                              <button
                                className="btn btn-warning mr-2"
                                style={{ marginRight: "20px" }}
                                onClick={() => handleUnRemove(player.user._id)}
                              >
                                Un-Remove
                              </button>
                            ) : null}
                            {player.booking_status === "cancelled" ? (
                              <button
                                className="btn btn-warning mr-2"
                                style={{ marginRight: "20px" }}
                                onClick={() => handleUnRemove(player.user._id)}
                              >
                                Re-Sign Up
                              </button>
                            ) : null}
                            {player.booking_status === "banned" ? (
                              <button
                                className="btn btn-danger "
                                onClick={() => handleUnBan(player.user._id)}
                              >
                                Un-Ban
                              </button>
                            ) : null}
                            {player.booking_status === "feiji" ? (
                              <button
                                className="btn btn-danger mr-2"
                                style={{ marginRight: "20px" }}
                                onClick={() => handleUnFeiji(player.user._id)}
                              >
                                UnFeiji
                              </button>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )} */}
      {/* LOG UI */}
      <div className="card mt-4">
        <div className="card-header bg-info text-white">
          <h4 className="mb-0">Chat</h4>
        </div>
        <div className={styles.chatroomContainer}>
          {/* Log display */}
          <div className={styles.chatLogs}>
            {court.logs && court.logs.length > 0 ? (
              court.logs
                .filter((log) => log.action === "commented")
                .map((log, index) => {
                  let message = "";
                  switch (log.action) {
                    case "commented":
                      message = log.comment;
                      break;
                    default:
                      message = "";
                  }

                  return (
                    <div key={index} className={styles.chatMessages2}>
                      <span className={styles.timestamp}>
                        {new Date(log.timestamp).toLocaleString()}
                      </span>
                      <span> - </span>
                      <span className={styles.username}>
                        {log.user.username} :
                      </span>
                      <span className={styles.messageContent}> {message}</span>
                    </div>
                  );
                })
            ) : (
              <p>No logs available.</p>
            )}
            <div ref={endOfChatRef}></div>{" "}
            {/* This empty div will be our scrolling target */}
          </div>

          {/* Comment Section */}
          <div className={styles.chatInputSection}>
            <form onSubmit={handleAddComment}>
              <textarea
                className="form-control"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Enter your message here..."
              ></textarea>
              <button
                className={`${styles.fullWidthButton} btn btn-primary mt-2`}
                disabled={isLoading}
                type="submit"
              >
                {isLoading ? "Sending..." : "Send"}
              </button>
            </form>
          </div>
        </div>
      </div>
      {/* LOG UI */}
      {((currentUser.role === "pro" &&
        currentUser.username === court.created_by?.username) ||
        currentUser.role === "admin") && (
        <div className="card mt-4">
          <div className="card-header bg-success text-white">
            <h4 className="mb-0">Court Logs</h4>
          </div>
          <div className={styles.chatroomContainer}>
            {/* Log display */}
            <div className={styles.chatLogs}>
              {court.logs && court.logs.length > 0 ? (
                court.logs
                  .filter((log) => log.action !== "commented")
                  .map((log, index) => {
                    let message = "";
                    switch (log.action) {
                      case "created":
                        message = "Created the court.";
                        break;
                      case "joined":
                        message = "Joined the court.";
                        break;
                      case "verified":
                        message = "Verified the court";
                        break;
                      case "modified":
                        message = `${log.comment}`;
                        break;
                      case "feiji":
                        message = `${log.comment}`;
                        break;
                      case "removed":
                        message = ` ${log.comment}`;
                        break;
                      case "undo":
                        message = ` ${log.comment}`;
                        break;
                      case "banned":
                        message = ` ${log.comment}`;
                        break;
                      case "completed":
                        message = ` ${log.comment}`;
                        break;
                      default:
                        message = "";
                    }

                    return (
                      <div key={index} className={styles.chatMessages2}>
                        <span className={styles.timestamp}>
                          {new Date(log.timestamp).toLocaleString()}
                        </span>
                        <span> - </span>
                        <span className={styles.username}>
                          {log.user?.username} :
                        </span>
                        <span className={styles.messageContent}>
                          {" "}
                          {message}
                        </span>
                      </div>
                    );
                  })
              ) : (
                <p>No logs available.</p>
              )}

              {/* This empty div will be our scrolling target */}
            </div>
          </div>
        </div>
      )}
      <Modal
        isOpen={showPasswordModal}
        onRequestClose={handleModalClose}
        contentLabel="Password Input Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            position: "relative",
            outline: "none",
            width: "100%",
            maxWidth: "500px",
            top: "auto",
            left: "auto",
            right: "auto",
            bottom: "auto",
          },
        }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Enter Room Pin</h5>
              <button
                type="button"
                className="close"
                onClick={() => setShowPasswordModal(false)}
                style={{
                  background: "transparent",
                  border: "none",
                  color: "#333",
                  fontSize: "1.5rem",
                  opacity: "0.7",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <input
                type="text"
                className="form-control"
                value={enteredPassword}
                onChange={(e) => {
                  if (e.target.value.match(/^\d{0,6}$/)) {
                    setEnteredPassword(e.target.value);
                  }
                }}
                placeholder="Enter 6 number Pin"
                pattern="\d{6}"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancelModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={verifyPassword}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={avatarModalIsOpen}
        onRequestClose={() => setAvatarModalIsOpen(false)}
        style={{
          content: {
            position: "relative",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            padding: "0",
            border: "1px solid #ccc",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            outline: "none",
            width: "80%", // For mobile
            maxWidth: "500px", // For desktop
          },
          overlay: {
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: "999",
          },
        }}
      >
        <div style={{ position: "relative" }}>
          {/* <img
            style={{
              width: "100%",
              height: "auto",
            }}
            src={`data:image/jpeg;base64,${selectedUser?.avatar}`}
            alt={`${selectedUser?.username}'s avatar`}
          /> */}
          <button
            style={{
              position: "absolute",
              top: "15px",
              right: "15px",
              background: "#ffffff",
              border: "2px solid #333",
              borderRadius: "50%",
              cursor: "pointer",
              padding: "10px",
              fontSize: "20px",
              lineHeight: "1",
              color: "#333",
              width: "50px",
              height: "50px",
              textAlign: "center",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
              zIndex: 1000,
            }}
            onClick={() => setAvatarModalIsOpen(false)}
          >
            &times;
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default CourtDetail;
