import axios from "axios";
const apiBaseURL = process.env.REACT_APP_BACKEND_URL;
function parseJwt(token) {
  try {
    const base64 = token.split(".")[1].replace(/-/g, "+").replace(/_/g, "/");
    const rawData = atob(base64);
    const len = rawData.length;
    const array = new Uint8Array(new ArrayBuffer(len));

    for (let i = 0; i < len; i++) {
      array[i] = rawData.charCodeAt(i);
    }

    const text = new TextDecoder().decode(array);
    return JSON.parse(text);
  } catch (e) {
    console.error("Error:", e); // Log the error for debugging
    return null;
  }
}
export const loginUser = (user) => ({
  type: "LOGIN_USER",
  payload: user,
});

export const loginError = (error) => ({
  type: "LOGIN_ERROR",
  payload: error,
});
export const resetSuccess = (message) => ({
  type: "RESET_SUCCESS",
  payload: message,
});
export const authenticateUser = (formData, navigate) => async (dispatch) => {
  try {
    const response = await axios.post(`${apiBaseURL}/users/login`, formData);

    const token = response.data.token;
    const userData = token ? parseJwt(token) : null;

    if (token) {
      localStorage.setItem("userToken", token);
      dispatch(loginUser(userData)); // assuming response.data.user exists
      navigate("/courts");
    }
  } catch (error) {
    //console.log(error);

    dispatch(loginError(error.response?.data?.message || "An error occurred."));
  }
};
export const authenticateUserWithOTP =
  (formData, navigate) => async (dispatch) => {
    try {
      const response = await axios.post(
        `${apiBaseURL}/users/login/verify`,
        formData
      );
      const token = response.data.token;
      const userData = token ? parseJwt(token) : null;

      if (token) {
        localStorage.setItem("userToken", token);
        dispatch(loginUser(userData)); // assuming response.data.user exists

        navigate("/courts");
      }
    } catch (error) {
      //console.log(error);
      dispatch(
        loginError(error.response?.data?.message || "An error occurred.")
      );
    }
  };
export const resetUserPassWithOTP =
  (formData, navigate) => async (dispatch) => {
    try {
      const response = await axios.post(
        `${apiBaseURL}/users/resetpass`,
        formData
      );

      dispatch(resetSuccess(response.data.message));
      navigate("/login");
    } catch (error) {
      //console.log(error);
      dispatch(
        loginError(error.response?.data?.message || "An error occurred.")
      );
    }
  };
export const logoutUser = () => ({
  type: "LOGOUT_USER",
});

export const userLogout = (navigate) => async (dispatch) => {
  // Remove the user token from localStorage
  localStorage.removeItem("userToken");

  // Dispatch the logout action to clear user data from Redux store
  dispatch(logoutUser());

  // Navigate the user to the login page or wherever you wish them to go post-logout
  navigate("/login");
};

export const rehydrateUser = (user) => ({
  type: "REHYDRATE_USER",
  payload: user,
});
